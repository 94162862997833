import { Component, OnInit } from '@angular/core';
import { Category } from 'src/app/model/category.model';
import { Product } from 'src/app/model/product.model';
import { MysqlService } from 'src/app/core/services/mysql.service';
import { cartService } from 'src/app/core/services/cart.service';
import { User } from 'src/app/model/user.model';


@Component({
  selector: 'app-userProducts',
  templateUrl: './userProducts.component.html',
  styleUrls: ['./userProducts.component.css']
})

export class userProductsComponent implements OnInit {
  users!: User[];
  products!: Product[];
  categories!: Category[];
  selectedUserCodMexal!: string;

  constructor(
    private mysql: MysqlService,
    private cart: cartService
  ) { }

  ngOnInit(): void {
    this.mysql.getTable('getCategories').subscribe((data: any) => {
      this.categories = Object.keys(data.data).map((key) => {
        return new Category(
          data.data[key].Id,
          data.data[key].Descrizione
        )
      });
    });
    this.mysql.getTable('getUsers').subscribe((data: any) => {
      this.users = Object.keys(data.data).map((key) => {
        return new User(
          data.data[key].email,
          data.data[key].success,
          data.data[key].token,
          data.data[key].cod_mexal,
          data.data[key].id_installazione,
          data.data[key].azienda,
          data.data[key].cod_destinazione,
          data.data[key].ruolo,
          data.data[key].name,
          data.data[key].active,
          data.data[key].par1
        )
      });
    });
  };

  changeProductStatus(product: Product) {
    interface MyData {
      active: string;
      product_reference: string;
      user_mexal_code: string;
    }

    let data: MyData = {
      active: product.Active == '0' ? '1' : '0',
      product_reference: product.Codice,
      user_mexal_code: this.selectedUserCodMexal
    };
    this.mysql.updateUserProducts('updateUserProducts', data).subscribe(() => {
      product.Active = product.Active == '0' ? '1' : '0';

    });
  }

  onSelectChange(value: string) {
    this.mysql.getProducts('getProducts', value).subscribe((data: any) => {
      this.products = Object.keys(data.data).map((key) => {
        data.data[key].Qta_finale = Number(data.data[key].Qta_per_pezzo) * this.cart.getQta(data.data[key].Codice);
        return new Product(
          data.data[key].Id,
          data.data[key].Codice,
          data.data[key].Descrizione,
          data.data[key].Categoria,
          this.cart.getQta(data.data[key].Codice),
          data.data[key].Prezzo,
          data.data[key].Qta_per_pezzo,
          data.data[key].Qta_finale,
          data.data[key].Unita_kg_lt,
          data.data[key].Um_1,
          data.data[key].Um_2,
          data.data[key].Active,
        )
      });
    })
  }
}
