<div class="wrapper">
  <select [(ngModel)]="selectedUserCodMexal" (change)="onSelectChange(selectedUserCodMexal)">
    <ng-container *ngFor="let user of users; let x = index">
      <option [value]="user.cod_mexal" *ngIf="user.ruolo === 'U'">
        {{ user.cod_mexal }} - {{ user.email }}
      </option>
    </ng-container>
  </select>
  <br><br>
  <ng-container *ngIf="selectedUserCodMexal">
    <div><h1>Categorie</h1></div>
    <div *ngFor="let category of categories; let x = index">
      <app-card [title]='category.Descrizione' [isScrollable]="false">
        <div *ngFor="let product of products; let i = index">
          <div id="row-container" *ngIf="category.Id == product.Categoria">
            <div *ngIf="product.Unita_kg_lt == '1' || (product.Unita_kg_lt != '1' && product.Unita_kg_lt != '2')" id="row-item">{{product.Descrizione}} ({{product.Um_1}})</div>
            <div *ngIf="product.Unita_kg_lt == '2'" id="row-item">{{product.Descrizione}} ({{product.Um_2}})</div>
            <div id="row-push-right" style="display: inline-flex;">
              <button
                type="button"
                (click)="changeProductStatus(product)"
                [ngStyle]="{'background-color': product.Active == '1' ? 'green' : 'red'}"
              ><i class="bi bi-circle-fill"></i></button>
            </div>
          </div>
        </div>
        <app-separator></app-separator>
      </app-card>
    </div>
  </ng-container>
</div>
