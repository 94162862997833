

<div class="wrapper">
  <ng-container *ngIf="authService.user?.id_installazione">
    <div style="text-align: center;"><img [src]="'/assets/' + authService.user?.id_installazione + '.png'" width="100"></div>
  </ng-container>
  <nav class="navbar navbar-expand-lg navbar-dark bg-purple">
    <div class="row" *ngIf="authService.user?.name">
      <div class="col-3">
        <button class="navbar-toggler" type="button" (click)="toggleNavbar()">
          <span class="navbar-toggler-icon"> </span>
        </button>
      </div>
      <div style="padding-left: 20px;" class="col-9">
        <a class="navbar-brand">
          {{authService.user?.name}}
          <br>
          {{authService.user?.email}}
        </a>
      </div>
    </div>

    <div class="collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen }">
      <ul class="navbar-nav mx-auto">
        <br>
        <li *ngIf="authService.user?.ruolo == 'A'" routerLink="configuration" class="nav-item" (click)="hideNavbar()">
          <a class="nav-link">CONFIGURAZIONE</a>
        </li>
        <li routerLink="menu" class="nav-item" (click)="hideNavbar()">
          <a class="nav-link">MENÙ</a>
        </li>
        <li routerLink="carello" class="nav-item" (click)="hideNavbar()">
          <a class="nav-link">CARRELLO ({{cartService.items.length}})</a>
        </li>
        <li routerLink="orders" class="nav-item" (click)="hideNavbar()">
          <a class="nav-link">ORDINI</a>
        </li>
        <li class="nav-item">
          <a (click)="onLogout()" class="nav-link" (click)="hideNavbar()">LOGOUT</a>
        </li>
      </ul>
    </div>
    <br>
    <button *ngIf="!navbarOpen && authService.user?.name && currentComponent != 'carello'" type="button" routerLink="carello"><i class="bi bi-cart">({{ cartService.items.length }})</i></button>
    <button *ngIf="!navbarOpen && authService.user?.name && currentComponent == 'carello'" type="button" routerLink="menu"><i class="bi bi-list">({{ cartService.items.length }})</i></button>
  </nav>
</div>
