import { Injectable } from '@angular/core';
import { User } from '../model/user.model';
import { MysqlService } from '../core/services/mysql.service';
import { cartService } from '../core/services/cart.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLoggedIn = false;
  user : User | undefined;

  constructor(
    private mysql: MysqlService,
    private cartService: cartService
  ) { }

  isAuthenticated(){
    return this.isLoggedIn;
  }

  login(body: any){
    return this.mysql.getToken(body);
  }

  logout(){
    this.isLoggedIn = false;
    this.user = undefined;
    localStorage.removeItem('user');
    this.cartService.totalWeight = 0;
    this.cartService.totalQta = 0;
  }

  createUser(email: string, success: boolean, token: string, cod_mexal: string, id_installazione: string, azienda:string, cod_destinazione: string, ruolo: string, name: string, active: string, par1: string) {
    this.user = new User(email, success, token, cod_mexal, id_installazione, azienda, cod_destinazione, ruolo, name, active, par1);
    this.isLoggedIn = true;
  }
}
