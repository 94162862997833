import { Component, OnInit } from '@angular/core';
import { Order } from 'src/app/model/order.model';
import { MysqlService } from 'src/app/core/services/mysql.service';
import { AuthService } from  'src/app/auth/auth.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})

export class OrdersComponent implements OnInit {
  orders!: Order[];
  orders_scaricati!: Order[];

  constructor(
    private mysql: MysqlService,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
    this.mysql.getOrders('getOrders', '0').subscribe({
      next: (data: any) => {
        this.orders = Object.keys(data.data).map((key) => {
          return new Order(
            data.data[key].order_id,
            data.data[key].date_added_formatter,
            data.data[key].cod_destinazione,
            data.data[key].prodotti,
          )
        });
      },
      error: (error: any) => {
      }
    });

    this.mysql.getOrders('getOrders', '1').subscribe((data: any) => {
      this.orders_scaricati = Object.keys(data.data).map((key) => {
        return new Order(
          data.data[key].order_id,
          data.data[key].date_added_formatter,
          data.data[key].cod_destinazione,
          data.data[key].prodotti,
        )
      });
    });
  };
}
