import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-card',
  animations: [
    trigger('collapsable', [
      state('openend', style({
        height: '*'
      })),
      state('closed', style({
        height: 0,
        padding: 0
      })),
      transition('opened <=> closed', [
        animate('0.7s cubic-bezier(0.83, 0, 0.17, 1)')
      ]),

    ])
  ],
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent {
  @Input() title:           string  | undefined;
  @Input() isScrollable:    boolean = false ;
  @Input() customCls:       string  | undefined;
  @Input() centeredContent: boolean | undefined;
  @Input() padding = true;

  @HostBinding('className') get className() {
  let cls = 'card';
  cls += this.isScrollable ? ' scrollable' : '';
  cls += this.customCls ? ` ${this.customCls}` : '';
    return cls;
  }

  state = 'openend';

  toggle() {
    this.state = this.state === 'opened' ? 'closed' : 'opened';
  }

  constructor() { }
}
