import { Component, OnInit } from '@angular/core';
import { Category } from 'src/app/model/category.model';
import { Product } from 'src/app/model/product.model';
import { MysqlService } from 'src/app/core/services/mysql.service';
import { cartService } from 'src/app/core/services/cart.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})

export class menuComponent implements OnInit {

  displayedColumns: string[] = ['Id', 'Codice', 'Descrizione'];
  datasrouce = ELEMENT_DATA;

  products!: Product[];
  categories!: Category[];

  constructor(
    private mysql: MysqlService,
    private cart: cartService
  ) { }

  ngOnInit(): void {
    this.mysql.getTable('getCategories').subscribe((data: any) => {
      this.categories = Object.keys(data.data).map((key) => {
        return new Category(
          data.data[key].Id,
          data.data[key].Descrizione
        )
      });
    });
    this.mysql.getProducts('getProducts', "0").subscribe((data: any) => {
      this.products = Object.keys(data.data).map((key) => {
        data.data[key].Qta_finale = Number(data.data[key].Qta_per_pezzo) * this.cart.getQta(data.data[key].Codice);
        return new Product(
          data.data[key].Id,
          data.data[key].Codice,
          data.data[key].Descrizione,
          data.data[key].Categoria,
          this.cart.getQta(data.data[key].Codice),
          data.data[key].Prezzo,
          data.data[key].Qta_per_pezzo,
          data.data[key].Qta_finale,
          data.data[key].Unita_kg_lt,
          data.data[key].Um_1,
          data.data[key].Um_2,
          data.data[key].Active,
        )
      });

      this.datasrouce = Object.keys(data.data).map((key) => {
        return new Product(
          data.data[key]['Id'],
          data.data[key]['Codice'],
          data.data[key]['Descrizione'],
          data.data[key]['Categoria'],
          this.cart.getQta(data.data[key].Codice),
          data.data[key]['Prezzo'],
          data.data[key]['Qta_per_pezzo'],
          data.data[key]['Qta_finale'],
          data.data[key]['Unita_kg_lt'],
          data.data[key]['Um_1'],
          data.data[key]['Um_2'],
          data.data[key]['Active'],
        )
      });
    })
  };

  addToCart(product: Product, category: Category ) {
    return product.Qta = this.cart.addToCart(product, category);
  };

  removeFromCart(product: Product) {
    return product.Qta = this.cart.delFromCart(product);
  }
}

export interface datasrouce{
  Id: string;
  Codice: string;
  Descrizione: string;
}

const ELEMENT_DATA: datasrouce[] = []
