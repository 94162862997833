import { Component, OnInit } from '@angular/core';
import { MysqlService } from 'src/app/core/services/mysql.service';
import { User } from 'src/app/model/user.model';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.css']
})

export class ConfigurationComponent implements OnInit {

  users!: User[];
  logged_user = Object.values(JSON.parse(localStorage.getItem('user')!));

  constructor(private mysql: MysqlService) {}

  ngOnInit(): void {
    this.mysql.getTable('getUsers').subscribe((data: any) => {
      this.users = Object.keys(data.data).map((key) => {
        return new User(
          data.data[key].email,
          data.data[key].success,
          data.data[key].token,
          data.data[key].cod_mexal,
          data.data[key].id_installazione,
          data.data[key].azienda,
          data.data[key].cod_destinazione,
          data.data[key].ruolo,
          data.data[key].name,
          data.data[key].active,
          data.data[key].par1
        )
      });
    });
  }

  changeUserStatus(user: User) {
    if (user.active == '0') {
      this.mysql.changeUserStatus(user.email, '1');
      user.active = '1';
    } else {
      this.mysql.changeUserStatus(user.email, '0');
      user.active = '0';
    }
  }
}
