export class User {
  constructor(
    public email : string,
    private _success : boolean,
    private _token : string,
    private _cod_mexal : string,
    public id_installazione : string,
    private _azienda : string,
    private _cod_destinazione : string,
    public ruolo : string,
    public name : string,
    public active : string,
    public par1 : string
  ){}

  get success() {
    if ( this._success = false ){
      return null;
    }
    return this._success;
  }

  get cod_mexal() {
    return this._cod_mexal;
  }

  get token() {
    return this._token;
  }

  get azienda() {
    return this._azienda;
  }

  get cod_destinazione() {
    return this._cod_destinazione;
  }
}
