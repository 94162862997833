import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { ConfigurationComponent } from './features/configuration/configuration.component';
import { menuComponent } from './features/menu/menu.component';
import { userProductsComponent } from './features/userProducts/userProducts.component';
import { OrdersComponent } from './features/orders/orders.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginModule } from './features/login/login.module';
import { CartModule } from './features/cart/cart.module';
import { RegisterModule } from './features/register/register.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [
      AppComponent,
      ConfigurationComponent,
      menuComponent,
      userProductsComponent,
      OrdersComponent,
    ],
    providers: [],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        SharedModule,
        CoreModule,
        LoginModule,
        CartModule,
        RegisterModule,
        ReactiveFormsModule,
        FormsModule
    ]
})
export class AppModule { }
