import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from  './../../../auth/auth.service';
import { cartService } from './../../../core/services/cart.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit{

  navbarOpen = false;
  currentComponent!: string;

  constructor(
    public  cartService: cartService,
    public  authService: AuthService,
    private router: Router
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentComponent = event.urlAfterRedirects.split('/')[1]; // Assumi che il componente sia nella seconda posizione nell'URL, es. '/componente-corrente'
      }
    });
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  hideNavbar() {
    if (this.navbarOpen) {
      this.navbarOpen = !this.navbarOpen;
    }
  }

  ngOnInit(): void {
    if (localStorage.getItem('user')){
      const user = JSON.parse(localStorage.getItem('user')!)
      this.authService.createUser(user.email, user.success, user.token, user.cod_mexal, user.id_installazione, user.azienda, user.cod_destinazione, user.ruolo, user.name, user.active, user.par1);
    }
  }

  onLogout() {
    this.cartService.items = [];
    this.authService.logout();
    this.router.navigateByUrl('/login');
  }
}
