import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { LoginComponent } from './features/login/login.component';
import { menuComponent } from './features/menu/menu.component';
import { OrdersComponent } from './features/orders/orders.component';
import { CartComponent } from './features/cart/cart.component';
import { ConfigurationComponent } from './features/configuration/configuration.component';
import { userProductsComponent } from './features/userProducts/userProducts.component';
import { RegisterComponent } from './features/register/register.component';

const routes: Routes = [
  {path: '', canActivate: [AuthGuard], children: [
    {path: '', redirectTo: 'menu', pathMatch: 'full'},
    {path: 'menu', component: menuComponent},
    {path: 'carello', component: CartComponent},
    {path: 'orders', component: OrdersComponent},
    {path: 'configuration', component: ConfigurationComponent},
    {path: 'userProducts', component: userProductsComponent},
    {path: 'register', component: RegisterComponent, data: {mode: 'destinazione'}},
    {path: 'registerUser', component: RegisterComponent, data: {mode: 'user'}},
  ]},
  {path: 'login', component: LoginComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
