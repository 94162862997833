<div class="wrapper centered">
  <div class="card vh-centered">
    <div class="title">Login - v 1.0</div>
      <div class="content">
        <form #loginForm="ngForm" (ngSubmit)="onSubmit(loginForm)">
          <input type="email"    placeholder="email"    name="email"    required ngModel>
          <input type="password" placeholder="password" name="password" required ngModel>
          <button type="submit" [disabled]="!loginForm.valid">Login</button>
        </form>
      </div>
    </div>
  </div>
