import { CommonModule } from '@angular/common';
import { NgModule }        from '@angular/core';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './components/navbar/navbar.component';

const components = [ NavbarComponent ]

@NgModule({
  declarations: [ ...components ],
  exports: [ NavbarComponent],
  imports: [ CommonModule , RouterModule ]
})

export class CoreModule {}
