export class Product{
  constructor(
    private _Id: string,
    private _Codice: string,
    private _Descrizione: string,
    private _Categoria: number,
    private _Qta: number,
    private _Prezzo: number,
    private _Qta_per_pezzo: number,
    private _Qta_finale: number = _Qta_per_pezzo,
    private _Unita_kg_lt: string = _Unita_kg_lt,
    private _Um_1: string = _Um_1,
    private _Um2: string = _Um2,
    private _Active: string = _Active,
  ){}

  get Id(){
    return this._Id;
  }

  get Codice(){
    return this._Codice;
  }

  get Descrizione(){
    return this._Descrizione;
  }

  get Prezzo(){
    return this._Prezzo;
  }

  get Unita_kg_lt(){
    return this._Unita_kg_lt;
  }

  get Um_1(){
    return this._Um_1;
  }

  get Um_2(){
    return this._Um2;
  }

  get Qta_per_pezzo(){
    return this._Qta_per_pezzo;
  }

  set Qta_per_pezzo(val: number){
    this._Qta_per_pezzo = val;
  }

  get Qta_finale(){
    return this._Qta_finale;
  }

  set Qta_finale(val: number){
    this._Qta_finale = val;
  }

  get Categoria(){
    return this._Categoria;
  }

  get Qta(){
    return this._Qta;
  }

  set Qta(val: number){
    this._Qta = val;
  }

  get Active(){
    return this._Active;
  }

  set Active(val){
    this._Active = val;
  }

}
