export class Category{
    constructor(
      private _Id: number,
      private _Descrizione: string,
    ){}


    get Id(){
      return this._Id;
    }

    get Descrizione(){
      return this._Descrizione;
    }

  }
