<div class="wrapper">
  <div>
    <h1>Carrello</h1>
  </div>

  <div class="card">
    <div class="title">Prodotti in ordine ( {{ cart.items.length }} )</div>
    <div class="content">
      <div id="row-container">
        <div id="row-item-no-color"></div>
        <div id="row-push-right-no-border-cart">Kg</div>
        <div id="row-push-right-no-border">Colli</div>
        <div style="display: inline-flex; color: transparent;">-----</div>
      </div>
      <div id="row-container" *ngFor="let item of cart.items">
        <div *ngIf="item.product.Unita_kg_lt == '1' || (item.product.Unita_kg_lt != '1' && item.product.Unita_kg_lt != '2')" id="row-item">{{item.product.Descrizione}} ({{item.product.Um_1}})</div>
        <div *ngIf="item.product.Unita_kg_lt == '2'" id="row-item">{{item.product.Descrizione}} ({{item.product.Um_2}})</div>
        <div *ngIf="item.product.Qta_per_pezzo != null" id="row-push-right">{{item.product.Qta_finale | number:'1.3-3'}}</div>
        <div *ngIf="item.product.Qta_per_pezzo == null" id="row-push-right">0.000</div>
        <div id="row-push-right">{{item.qta}}</div>
        <div style="display: inline-flex;">
          <button type="button" (click)="cart.removeFromCart(item)"><i class="bi bi-trash"></i></button>
        </div>
        <app-separator></app-separator>
      </div>
      <div id="row-item-no-color"></div>
      <div id="row-item">Totale Peso: {{ cart.totalWeight | number:'1.3-3'}}</div>
      <div id="row-item">Totale Colli: {{ cart.totalQta }}</div>
      <button *ngIf="cart.items.length" type="submit" (click)="cart.proceed()">ORDINA</button>
      <br><br>
      <div class="horiz-grid">
        <div></div>
        <em>{{ cart.items.length }} items</em>
      </div>
    </div>
  </div>
</div>
