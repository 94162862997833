import { NgModule }           from '@angular/core';
import { SeparatorComponent } from './components/separator/separator.component';
import { CardComponent }      from './components/card/card.component';
import { CommonModule }       from '@angular/common';

const components = [
  CardComponent,
  SeparatorComponent,
]

@NgModule({
  declarations: [ ...components ],
  exports: [ ...components ],
  imports: [
    CommonModule
  ]
})

export class SharedModule {}
