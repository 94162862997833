<div class="wrapper">
  <div>
    <h1>Ordini</h1>
  </div>
  <div *ngIf="!orders && !orders_scaricati; else success">LOADING</div>

  <ng-template #success>
    <!-- <div *ngIf="orders"> -->
    <h2 *ngIf="orders">Da scaricare</h2>
    <ng-container *ngFor="let order of orders; let x = index">
      <app-card  *ngIf="order.Cod_destinazione == authService.user?.cod_destinazione" [title]="'Ordine n. ' + order.Id + ' del ' + order.Date" [isScrollable]="false">
        <div id="row-container" *ngFor="let product of order.Products; let i = index">
          <div id="row-item">{{product.name}}</div>
          <!-- <div *ngIf="product == '1'" id="row-item">{{product.name}} ({{product.Um_1}})</div> -->
          <!-- <div *ngIf="item.product.Unita_kg_lt == '2'" id="row-item">{{item.product.Descrizione}} ({{item.product.Um_2}})</div> -->
          <!-- <div *ngIf="item.product.Unita_kg_lt != '1' && item.product.Unita_kg_lt != '2'" id="row-item">{{item.product.Descrizione}}</div> -->
          <!-- <div *ngIf="product.quantity_per_piece != null" id="row-push-right">{{product.quantity_per_piece * product.quantity}}kg</div> -->
          <div id="row-push-right" style="display: inline-flex;">{{product.quantity}}</div>
        </div>
        <app-separator></app-separator>
      </app-card>
    </ng-container>
    <br><br>
    <!-- </div> -->
    <h2 *ngIf="orders_scaricati">Scaricati</h2>
    <ng-container *ngFor="let order of orders_scaricati; let x = index">
      <app-card *ngIf="order.Cod_destinazione == authService.user?.cod_destinazione" [title]="'Ordine n. ' + order.Id + ' del ' + order.Date" [isScrollable]="false">
        <div id="row-container" *ngFor="let product of order.Products; let i = index">
          <div id="row-item">{{product.name}}</div>
          <!-- <div *ngIf="product.quantity_per_piece != null" id="row-push-right">{{product.quantity_per_piece * product.quantity}}kg</div> -->
          <div id="row-push-right" style="display: inline-flex;">{{product.quantity}}</div>
        </div>
        <app-separator></app-separator>
      </app-card>
    </ng-container>
  </ng-template>
</div>
