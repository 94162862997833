<div class="wrapper">
  <div>
    <h1>Categorie</h1>
  </div>
  <div style="text-align: center;" *ngIf="!products; else success">LOADING</div>

  <ng-template #success>
    <div *ngFor="let category of categories; let x = index">
      <app-card [title]='category.Descrizione' [isScrollable]="false">
        <div id="row-container">
          <div id="row-item-no-color"></div>
          <div id="row-push-right-qta-no-border">Kg</div>
          <div id="row-push-right-no-border" style="display: inline-flex;">
            <button style="padding-left: 5px; padding-right: 5px;" type="button" class="btn btn-light">
              <i class="bi bi-plus" style="color: transparent;"></i>
            </button>
            <button style="padding-left: 5px; padding-right: 5px;" type="button" class="btn btn-light">
              <i class="bi bi-dash" style="color: transparent;"></i>
            </button>
          </div>
          <div id="row-push-right-qta-tiny-no-border">Colli</div>
        </div>
        <div *ngFor="let product of products; let i = index">
          <div id="row-container" *ngIf="category.Id == product.Categoria && product.Active == '1'">
            <div *ngIf="product.Unita_kg_lt == '1' || (product.Unita_kg_lt != '1' && product.Unita_kg_lt != '2')" id="row-item">{{product.Descrizione}} ({{product.Um_1}})</div>
            <div *ngIf="product.Unita_kg_lt == '2'" id="row-item">{{product.Descrizione}} ({{product.Um_2}})</div>
            <div *ngIf="product.Qta_per_pezzo != null" id="row-push-right-qta">{{product.Qta_finale | number:'1.3-3'}}</div>
            <div *ngIf="product.Qta_per_pezzo == null" id="row-push-right-qta">0.000</div>
            <div id="row-push-right" style="display: inline-flex;">
              <button style="padding-left: 5px; padding-right: 5px;" type="button" class="btn btn-light" (click)="addToCart(product, category)">
                <i class="bi bi-plus"></i>
              </button>
              <button style="padding-left: 5px; padding-right: 5px;" type="button" class="btn btn-light" (click)="removeFromCart(product)">
                <i class="bi bi-dash"></i>
              </button>
            </div>
            <div id="row-push-right-qta-tiny">{{product.Qta}}</div>
          </div>
        </div>
        <app-separator></app-separator>
      </app-card>
    </div>
  </ng-template>
</div>
