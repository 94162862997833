export class Order{
  constructor(
    private _Id: string,
    private _Date: string,
    private _Cod_destinazione: string,
    private _Products:Array<{
      product_id: number
      name: string
      quantity: number
      quantity_per_piece: number
    }>
  ){}

  get Id(){
    return this._Id;
  }

  get Date(){
    return this._Date;
  }

  get Products(){
    return this._Products;
  }

  get Cod_destinazione(){
    return this._Cod_destinazione;
  }
}
