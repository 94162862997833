import { MysqlService } from 'src/app/core/services/mysql.service';
import { Category } from './../../model/category.model';
import { Injectable } from "@angular/core";
import { CartItem } from "src/app/model/cart-item";
import { Product } from "src/app/model/product.model";

@Injectable({
    providedIn: 'root'
})

export class cartService{
  items: CartItem[] = [];
  totalWeight: number = 0;
  totalQta: number = 0;

  constructor(private mysql: MysqlService) {}

  getQta(codice: string) {
    let myQta = 0;
    this.items.forEach(element => {
      if (element.product.Codice === codice){
        myQta = element.qta;
      }
    });
    return myQta;
  }

  addToCart(product: Product, category: Category) {
    let qtaAdded = false;
    let myQta = 1;
    this.items.forEach(element => {
      if (element.product.Codice === product.Codice) {
        element.qta = element.qta + 1;
        qtaAdded= true;
        myQta = element.qta;
        element.product.Qta_finale = parseFloat((Number(element.product.Qta_per_pezzo) * myQta).toFixed(3));
      }
    });

    if (qtaAdded === false) {
      this.items = [
          ...this.items,
          {
              category: category,
              product: product,
              qta: 1
          }
      ];
    }
    product.Qta_finale = parseFloat((Number(product.Qta_per_pezzo) * myQta).toFixed(3));
    this.totalWeight += Number(product.Qta_per_pezzo)
    this.totalQta ++
    return myQta;
  };

  delFromCart(product: Product) {
    let qtaSubtracted = false;
    let myQta = 0;
    this.items.forEach((element, index)=> {
      if (element.product.Codice === product.Codice && element.qta > 0) {
        element.qta = element.qta - 1;
        qtaSubtracted = true;
        myQta = element.qta;
        element.product.Qta_finale = parseFloat((Number(element.product.Qta_per_pezzo) * myQta).toFixed(3));
        if (myQta == 0) {
          this.items.splice(index,1);
        }
      }
    });
    product.Qta_finale = parseFloat((Number(product.Qta_per_pezzo) * myQta).toFixed(3));
    this.totalWeight -= Number(product.Qta_per_pezzo)
    if (this.totalWeight < 0) this.totalWeight = 0;
    this.totalQta --
    if (this.totalQta < 0) this.totalQta = 0;
    return myQta;
  };

  removeFromCart(cartItem: CartItem) {
    let qtaSubtracted = false;
    let myQta = 1;

    this.items.forEach(element => {
      if ( element.product.Codice === cartItem.product.Codice ){
        if ( element.qta > 0 ) {
          element.qta = element.qta - 1;
          if ( element.qta > 0 ) qtaSubtracted = true;
        }
        myQta = element.qta;
      }
    });

    if ( qtaSubtracted === false ) {
      this.items = this.items.filter(item => item.product.Codice !== cartItem.product.Codice )
    }
    cartItem.product.Qta_finale = parseFloat((Number(cartItem.product.Qta_per_pezzo) * myQta).toFixed(3));
    this.totalWeight -= Number(cartItem.product.Qta_per_pezzo)
    this.totalQta --
    return myQta;
  };

  proceed() {
    interface Order {
      data: string;
      rows: any[];
      cod_mexal: string;
      cod_destinazione: string;
      totalWeight: number;
      totalQta: number;
    }

    const user = JSON.parse(localStorage.getItem('user')!);
    const cod_mexal = user._cod_mexal;
    const cod_destinazione = user._cod_destinazione;
    const order: Order = {data : '0', rows: [], cod_mexal: cod_mexal, cod_destinazione: cod_destinazione, totalWeight: this.totalWeight, totalQta: this.totalQta};
    var orderedQta = 0;
    for( var index in this.items ) {
      order.rows.push({
        'Codice' : this.items[index]['product']._Codice,
        'Descrizione' : this.items[index]['product']._Descrizione,
        'Qta' : this.items[index]['product'].Qta_per_pezzo != null ? this.items[index].qta * this.items[index]['product'].Qta_per_pezzo : this.items[index].qta,
        'Colli' : this.items[index].qta,
        'Um' : this.items[index]['product'].Unita_kg_lt == "2" ? "2" : "1",
        'Prezzo' : this.items[index]['product']._Prezzo,
      });
      orderedQta += this.items[index]['product'].Qta_per_pezzo != null ? this.items[index].qta * this.items[index]['product'].Qta_per_pezzo : this.items[index].qta;
    }
    const requestedQta = user.par1;
    if (orderedQta >= requestedQta) {
      this.mysql.putOrder('putOrder', JSON.stringify(order));
      this.items = [];
      this.totalWeight = 0;
      this.totalQta = 0;
      window.alert("Ordine confermato.");
    } else {
      window.alert("Quantità richiesta non soddisfatta.");
    }
  }
}
