<div class="wrapper">
  <div *ngIf="logged_user[7] == 'A'">
    <div style="text-align: center">
      <h1>Configurazione</h1>
      <h2>Admin</h2>
      <div id="row-item">Email: {{logged_user[0]}}</div>
      <div id="row-item">Id installazione: {{logged_user[4]}}</div>
      <div id="row-item">Azienda: {{logged_user[5]}}</div>
      <div id="row-item">Codice Mexal: {{logged_user[3]}}</div>
      <div id="row-item">Codice Destinazione: {{logged_user[6]}}</div>
      <br><br>
      <h2>Users</h2>
      <table class="table-users">
        <tr>
          <th>Email</th>
          <th>Codice Mexal </th>
          <th></th>
        </tr>
        <ng-container *ngFor="let user of users; let i = index">
          <tr *ngIf="logged_user[4] == user.id_installazione && user.ruolo == 'U'">
            <td id="row-item">{{user.email}}</td>
            <td id="row-item">{{user.cod_mexal}}</td>
            <td id="icon-item"><button type="button" (click)="changeUserStatus(user)" [ngStyle]="{'background-color': user.active == '1' ? 'green' : 'red'}"><i class="bi bi-circle-fill"></i></button></td>
            <br>
          </tr>
        </ng-container>
      </table>
      <button routerLink="/registerUser" mat-raised-button color="primary" type="submit">Crea User</button>
      <br><br>
      <h2>Destinazioni</h2>
      <table class="table-destinazioni">
        <tr>
          <th>Email</th>
          <th>Codice Mexal</th>
          <th>Cod Des</th>
          <th></th>
        </tr>
        <ng-container *ngFor="let user of users; let i = index">
          <tr *ngIf="logged_user[4] == user.id_installazione && user.ruolo == 'D'">
            <td id="row-item">{{user.email}}</td>
            <td id="row-item">{{user.cod_mexal}}</td>
            <td id="row-item">{{user.cod_destinazione}}</td>
            <td id="icon-item"><button type="button" (click)="changeUserStatus(user)" [ngStyle]="{'background-color': user.active == '1' ? 'green' : 'red'}"><i class="bi bi-circle-fill"></i></button></td>
            <br>
          </tr>
        </ng-container>
      </table>
      <button routerLink="/register" mat-raised-button color="primary" type="submit">Crea Destinazione</button>
      <br><br>
      <button routerLink="/userProducts" mat-raised-button color="primary" type="submit">Sync prodotti per user</button>
    </div>
  </div>
</div>
