import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MysqlService } from 'src/app/core/services/mysql.service';
import { User } from 'src/app/model/user.model';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})

export class RegisterComponent implements OnInit {
  mode!: string;
  users!: User[];
  logged_user = Object.values(JSON.parse(localStorage.getItem('user')!));
  register_form!: FormGroup;
  selected_user!: User[];
  passwordHidden = true;
  
  constructor(
    private mysql: MysqlService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  onSelectionChange(event: MatSelectChange) {
    this.selected_user = event.value;
  }

  togglePasswordVisibility(): void {
    this.passwordHidden = !this.passwordHidden;
  }

  ngOnInit(): void {
    this.mode = this.route.snapshot.data['mode'];
    this.register_form = new FormGroup({
      name: new FormControl(),
      email: new FormControl(),
      password: new FormControl(),
      cod_destinazione: new FormControl(),
      cod_mexal: new FormControl(),
      par1: new FormControl(),
    })
    this.mysql.getTable('getUsers').subscribe((data: any) => {
      this.users = Object.keys(data.data).map((key) => {
        return new User(
          data.data[key].email,
          data.data[key].success,
          data.data[key].token,
          data.data[key].cod_mexal,
          data.data[key].id_installazione,
          data.data[key].azienda,
          data.data[key].cod_destinazione,
          data.data[key].ruolo,
          data.data[key].name,
          data.data[key].active,
          data.data[key].par1,
        )
      });
    });
  }

  onSubmit() {
    const logged_user = Object.values(JSON.parse(localStorage.getItem('user')!));
    if (this.mode == "destinazione") {
      this.mysql.register('registerWeb', this.register_form, logged_user, this.selected_user, "D").add(() => {
        this.router.navigate(['/configuration']);
      });
    } else if (this.mode == "user") {
      this.mysql.register('registerWeb', this.register_form, logged_user, "", "U").add(() => {
        this.router.navigate(['/configuration']);
      });
    }
  }
}
