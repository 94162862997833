import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  onSubmit(form: NgForm) {
    const email    = form.value.email;
    const password = form.value.password;
    this.authService.login({ email, password }).subscribe({
      next: (data: any) => {
        if (data.success && data.active == '1') {
          this.authService.createUser(email, data.success, data.token, data.cod_mexal, data.id_installazione, data.azienda, data.cod_destinazione, data.ruolo, data.name, data.active, data.par1);
          localStorage.setItem('user', JSON.stringify(this.authService.user));
          this.router.navigateByUrl('/menu');
        } else {
          alert("Utente non attivo");
        }
      },
      error: (error: any) => {
        alert(error.error.message);
      }
    });
  }
}
