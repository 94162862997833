<div *ngIf="logged_user[7] == 'A'">
  <div class="wrapper">
    <form [formGroup]="register_form" (ngSubmit)="onSubmit()">
      <div *ngIf="mode == 'user'">
        <mat-form-field appearence="fill">
          <mat-label>Codice mexal</mat-label>
          <input matInput type="text" name="cod_mexal" formControlName="cod_mexal"/>
        </mat-form-field>
      </div>
      <div *ngIf="mode == 'destinazione'">
        <mat-form-field appearence="fill">
          <mat-label>Codice mexal</mat-label>
          <mat-select placeholder="Seleziona il codice mexal dello user di riferimento" formControlName="cod_mexal" (selectionChange)="onSelectionChange($event)">
            <ng-container *ngFor="let user of users; let x = index">
              <mat-option [value]="user.cod_mexal" *ngIf="user.ruolo === 'U'">
                {{ user.cod_mexal }} - {{ user.email }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <br><br><br><br>
      <mat-form-field appearence="fill">
        <mat-label>Nome</mat-label>
        <input matInput type="text" name="name" formControlName="name"/>
      </mat-form-field>
      <mat-form-field appearence="fill">
        <mat-label>Email</mat-label>
        <input matInput type="text" name="email" formControlName="email"/>
      </mat-form-field>

      <mat-form-field appearence="fill">
        <mat-label>Password</mat-label>
        <div class="password-input-container">
          <input matInput type="{{ passwordHidden ? 'password' : 'text' }}" name="password" formControlName="password"/>
          <ng-container *ngIf="passwordHidden == true">
            <button type="button" (click)="togglePasswordVisibility()"><i class="bi bi-eye-fill"></i></button>
          </ng-container>
          <ng-container *ngIf="passwordHidden == false">
            <button type="button" (click)="togglePasswordVisibility()"><i class="bi bi-eye-slash"></i></button>
          </ng-container>
        </div>
      </mat-form-field>

      <div *ngIf="mode == 'user'">
        <mat-form-field appearence="fill">
          <mat-label>Quantità minima da ordinare</mat-label>
          <input matInput type="text" name="par1" formControlName="par1"/>
        </mat-form-field>
      </div>
      <ng-container *ngIf="mode == 'destinazione'">
        <mat-form-field appearence="fill">
          <mat-label>Codice destinazione</mat-label>
          <input matInput type="text" name="cod_destinazione" formControlName="cod_destinazione"/>
        </mat-form-field>
      </ng-container>
      <button mat-raised-button color="primary" type="submit">Crea Utente</button>
    </form>
  </div>
</div>

