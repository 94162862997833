import { Component, OnInit } from '@angular/core';
import { cartService } from 'src/app/core/services/cart.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {
  constructor(public cart: cartService) {}
  ngOnInit(): void {}
}
