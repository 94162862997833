import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { enviroment } from 'src/environments/enviroment';

@Injectable({
  providedIn: 'root'
})

export class MysqlService {
  url      = '';
  protocol = enviroment.protocol;
  dominio  = enviroment.dominio;
  login    = 'api/auth/loginWeb';
  apiAuth  = 'api/auth/';
  apiSync  = 'api/sync/';
  apiApp01 = 'api/app01/';
  token    = '';

  header = {
    headers: new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Accept', 'application/json')
};

  constructor(private http: HttpClient) { }
    getToken(body: {} ){
      return this.http.post(this.url.concat(this.protocol, '://', this.dominio, this.login), body, this.header );
    };

    getTable(archivio: string){
      var pathUrl = this.url.concat(this.protocol, '://', this.dominio, this.apiSync, archivio);
      if (localStorage.getItem('user')){
        const user = JSON.parse(localStorage.getItem('user')!)
        this.token = user._token;
      }
      return this.http.get(pathUrl, {
        headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('Authorization',  'Bearer ' + this.token)
      });
    };

    getProducts(archivio: string, user_mexal_code: string) {
      var pathUrl = this.url.concat(this.protocol, '://', this.dominio, this.apiSync, archivio);
      if (localStorage.getItem('user')){
        const user = JSON.parse(localStorage.getItem('user')!)
        this.token = user._token;
      }
      pathUrl += "/" + user_mexal_code;
      return this.http.get(pathUrl, {
        headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('Authorization',  'Bearer ' + this.token)
      });
    };

    getOrders(archivio: string, stateOrder: string){
      var pathUrl = this.url.concat(this.protocol, '://', this.dominio, this.apiSync, archivio);
      if (stateOrder == "0") pathUrl += "/0";
      else pathUrl += "/1"
      if (localStorage.getItem('user')){
        const user = JSON.parse(localStorage.getItem('user')!)
        this.token = user._token;
      }
      return this.http.get(pathUrl, {
        headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('Authorization',  'Bearer ' + this.token)
      });
    };

    putOrder(archivio: string, data: any ){
      var pathUrl = this.url.concat(this.protocol, '://', this.dominio, this.apiApp01, archivio);
      if (localStorage.getItem('user')){
        const user = JSON.parse(localStorage.getItem('user')!)
        this.token = user._token;
      }
      const body = data;
      return this.http.post(pathUrl, body, {
        headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('Authorization',  'Bearer ' + this.token)
      }).subscribe(result => { });
    };

    register(archivio: string, register_form: any, logged_user: any, selected_user: any, ruolo: string){
      var pathUrl = this.url.concat(this.protocol, '://', this.dominio, this.apiAuth, archivio);
      const body = register_form.value;
      body.id_installazione = logged_user[4];
      body.azienda = logged_user[5];
      body.ruolo = ruolo == "D" ? "D" : "U";
      body.par1 = ruolo == "D" ? selected_user.par1 : register_form.value.par1;
      return this.http.post(pathUrl, body, {
        headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('Authorization',  'Bearer ' + this.token)
      }).subscribe(result => { });
    };

    changeUserStatus(email: string, active: string){
      var pathUrl = this.url.concat(this.protocol, '://', this.dominio, this.apiAuth, 'changeUserStatus');
      const emailObj = { "email": email, "active": active};
      const body = JSON.stringify(emailObj);
      return this.http.post(pathUrl, body, {
        headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('Authorization',  'Bearer ' + this.token)
      }).subscribe(result => { });
    };

    updateUserProducts(archivio: string, data: any){
      var pathUrl = this.url.concat(this.protocol, '://', this.dominio, this.apiApp01, archivio);
      return this.http.post(pathUrl, data, {
        headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('Authorization',  'Bearer ' + this.token)
      });
    };
}
